<template>
  <div class="landing-my-cart">
    <img src="/assets/protein-logo-animation.gif" height="400" width="400" alt="Animation">
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { htmlDecode } from '@vue-storefront/core/filters';

export default {
  name: 'AddToCart',
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct',
      getOriginalProduct: 'product/getOriginalProduct',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent'
    }),
    breadcrumbs () {
      return this.getBreadcrumbsRoutes
        .map(route => ({
          text: htmlDecode(route.name),
          route: {
            link: route.route_link
          }
        }))
        .concat({
          text: htmlDecode(this.getBreadcrumbsCurrent)
        });
    }
  },
  async mounted () {
    this.isLoading = false
    if (this.getCurrentProduct && this.$route && this.$route.params && this.$route.params.qty <= this.getCurrentProduct.stock.qty) {
      this.getCurrentProduct.qty = this.$route.params.qty
      try {
        await this.$store.dispatch('cart/addItem', { productToAdd: this.getCurrentProduct })
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: this.$t('Product has been added to the cart!'),
          action1: { label: this.$t('OK') }
        });
        this.$router.push(this.localizedRoute('/checkout'));
      } catch (err) {
        await this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: err,
          action1: { label: this.$t('OK') }
        });
        this.$router.push(this.localizedRoute('/'))
      }
    } else {
      await this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('The quantity is currently unavailable.'),
        action1: { label: this.$t('OK') }
      });
      this.$router.push(this.localizedRoute('/'))
    }
  },
  async asyncData ({ store, route, context }) {
    if (context) context.output.cacheTags.add('product')
    const product = await store.dispatch('product/loadProduct', {
      parentSku: route.params.sku,
      childSku:
        route && route.params && route.params.sku
          ? route.params.sku
          : null
    });
  }
};
</script>

<style lang="scss">
.landing-my-cart {
  padding-top: var(--spacer-50);
  justify-content:center;
  display: flex;
  width: 100%;
  img {
    width: 800px;
    height: auto;
  }
}
</style>
